import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import ContentPart from "../../components/services/template/content"
import SprinklerInspectionsHero from "../../components/heros/sprinkerSystemsHero"

const TitleText = `
	Sprinkler System Inspection
`

const SprinklerSystemsInspectionPage = () => (
	<Layout>
		<Seo title={TitleText} description="At Relentless Fire and Safety, we install setup and maintain sprinkler systems, conduct regular inspections, and perform operational testing throughout the Okanagan." />

		<SprinklerInspectionsHero />

		<ContentWrapper>
			<ContentPart>
				<p>At Relentless Fire and Safety, we install setup and maintain sprinkler systems, conduct regular inspections, and perform operational testing throughout the Okanagan.</p>
				<p>A properly operating and well-maintained sprinkler system is crucial to any fire protection system. It keeps buildings, occupants, and emergency responders safe during emergencies. Sprinkler system types include deluge, wet pipes, and dry pipes.</p>
				<h2>Full-Service Fire Protection</h2>
				<p>Our technicians are here to help you should you need to adjust your existing sprinkler systems or are expanding your buildings structure and need additional sprinkler coverage.</p>
				<p><Link to="/contact/" title="contact relentless fire and safety">Contact us to learn about the options available to you.</Link></p>
				<h2>Inspection Requirements</h2>
				<p>Your sprinkler system components must be regularly monitored, preventing back-flow issues, and guarantees proper pumping. Local By-Laws establish the frequency and level of detail required to keep your sprinkler system up to code. Our trained professionals are on the cutting-edge of both technological innovation in our industry. They are always up to date with the latest fire protection regulation and policies here in the Okanagan. We will ensure your systems remain up to code</p>
				<p>Our technicians test your sprinkler infrastructure and communicate all results and shortcomings in your system on a weekly, monthly, and annual basis as required.  </p>
				<h2>Monthly and Quarterly Inspections</h2>
				<p>We inspect your water supply infrastructure every month, including all of the fire alarms and control equipment, valves, and piping components.</p>
				<p>Our Monthly safety inspections are scheduled in tandem with our quarterly and semi-annual systems inspections.</p>
				<h2>Annual Inspections</h2>
				<p>Every year your entire sprinkler system requires a full inspection. We look for noticeable changes and modifications, ensuring all damage is noted for repair. Systems inspected include piping, mounts, braces, sprinklers, valves, gauges, pumps, alarms, etc.</p>
				<p>Different sprinkler systems, such as wet, dry, and deluge, require specific tests to ensure their components are up to Okanagan’s local By-Laws. We will explain what type of system you have, how it works, and the necessary testing requirements when you book your inspection.</p>
				<p>Local, provincial, and federal regulations each have fixed multi-year testing mandates. These typically occur at 3, 5, 10, 20, and 50-year intervals, depending on your building and sprinkler system age. Relentless Fire and Safety records and monitors your testing frequency intervals to ensure you comply with all By-Laws and regulatory policies.</p>
				<p>Contact Relentless today to keep on top of your annual fire and safety inspections.</p>
			</ContentPart>
		</ContentWrapper>
	</Layout>
)

export default SprinklerSystemsInspectionPage